<template>
    <page v-bind="{ loading }" :icon="'users'" :title="`${Naming.Users} Persona Management`" :loadingText="'Loading...'">
      <div slot="tools">
        <router-link exact class="button is-rounded is-primary" :to="{ name: 'user-persona-create' }">
          <icon icon="plus"/>
          <span>{{ Naming.User }} Persona</span>
        </router-link>
        <router-link exact class="button is-rounded is-primary ml-1" :to="{ name: 'user-persona-transactions' }">
          <icon icon="receipt"/>
          <span>{{ Naming.User }} Persona Transaction</span>
        </router-link>
      </div>

      <div class="box is-paddingless">

        <form class="p-1">
          <text-input
              v-model="filters.search"
              :placeholder="`Search ${Naming.User} Personas`"
              classes="has-addons is-rounded">
            <template #right>
              <action-button
                  v-if="filters.search"
                  @click="clearPersonaSearch()">
                <icon icon="times"/>
              </action-button>
              <submit-button @submit="search" class="is-rounded" left-icon="search">
                Search
              </submit-button>
            </template>
          </text-input>
        </form>

        <table class="table is-fullwidth">
          <thead class="is-sticky">
          <tr>
            <th>Name</th>
            <th class="has-text-centered">{{ Naming.Sites }}</th>
            <th class="has-text-centered">{{ Naming.Technicians }}</th>
            <th class="has-text-centered">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="persona in personas.data" :key="persona.id">
            <td>
              <router-link class="is-block"
                           :to="{ name: 'user-persona-edit', params: { 'persona': persona.id }}">
                <span>{{ persona.name }}</span>
              </router-link>
            </td>
            <td class="has-text-centered">{{ persona.site_count }}</td>
            <td class="has-text-centered">
              {{ persona.technician_count }}
            </td>
            <td class="is-narrow has-text-centered">
              <action-button
                  @click="deletePersona(persona.id)"
                  class="is-danger"
                  data-tooltip="Delete"
              >
                <icon icon="trash"/>
              </action-button>
            </td>
          </tr>
          </tbody>
        </table>
        <p class="has-text-centered" v-if="!personas.data.length">
          No {{ Naming.Users.toLowerCase() }} personas found
        </p>
        <div class="columns p-1">
          <div class="column">
            <pager class="pl" :pageable="personas" @nav="goToPage" :context="Naming.User.toLowerCase() + ' personas'"/>
          </div>
          <div class="column is-narrow" v-if="implemented">
            <router-link
                class="button is-small is-rounded is-flex"
                :to="{
            name: 'deleted-personas-index'
          }">
              <icon icon="trash"/>
              <span>Deleted {{ Naming.User }} Personas</span>
            </router-link>
          </div>
        </div>
      </div>
    </page>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "Index",

  data: () => ({
    loading: true,
    filters: {
      role: '',
      search: ''
    },
    implemented: false
  }),

  async created() {
    this.filters = {...this.filters, ...this.$route.query}
    await this.loadPersonas()
    this.loading = false
  },

  methods: {
    ...mapActions("userPersonas", ["removePersona"]),
    async loadPersonas() {
      this.$scrollToTop()
      try {
        await this.$store.dispatch('userPersonas/loadPersonas', {
          path: '/api' + this.$route.fullPath,
          params: { search: this.filters.search }
        });
      } catch (error) {
        this.whoops()
      } finally {
        this.loading = false
      }
    },
    goToPage(path) {
      this.loading = true
      this.$router.push(path)
    },
    async search() {
      this.$scrollToTop()
      this.loading = true
      await this.$router.push({
        path: this.$route.path,
        query: {search: this.filters.search}
      });
    },
    async deletePersona(personaId) {

      this.loading = true
      const shouldDelete = await this.confirmDelete()
      if(shouldDelete === 1) {

        await this.removePersona(personaId)
        await this.loadPersonas()
      }
      this.loading = false
    },
    async confirmDelete() {

      return this.$confirm({
        title: "Warning: Delete Persona",
        message: `Deleting this ${this.Naming.User} Persona will detach the Persona from all linked ${this.Naming.Users} and will <b>not</b> affect their Site Access.`,
        confirmText: "Delete",
        cancelText: "Cancel",
        confirmClass: 'is-danger',
        cancelClass: 'is-info',
      })
          .catch(() => {
            this.$whoops()
          })
    },
    clearPersonaSearch() {
      this.loading = true
      this.filters.search = ''
      this.$router.push({ path: this.$route.path, query: {} })
    }
  },

  computed: {
    ...mapGetters('user', [
      'users'
    ]),
    ...mapGetters('company', [
      'company'
    ]),
    ...mapGetters('userPersonas', [
        'personas'
    ])
  },

  watch: {
    $route: 'loadPersonas',
  }
}
</script>